import React from 'react'
import Seo from '../components/SEO'
import styled from '@emotion/styled'
import { container } from '../styles/theme'

import PageBody from '../components/shared/page-body'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

const PrivacyPolicyPage = ({ data: { privacyPolicy, cookiePolicy } }) => (
  <Layout>
    <Seo title="Privacy Policy" pathname="/privacy-policy/" />
    <PageBody>
      <Container>
        <section>
          <h1 className="heading--h1">
            {privacyPolicy.childMarkdownRemark.frontmatter.title}
          </h1>
          <UpdatedDate>
            Last updated: {privacyPolicy.childMarkdownRemark.frontmatter.date}
          </UpdatedDate>
          <div
            dangerouslySetInnerHTML={{
              __html: privacyPolicy.childMarkdownRemark.html,
            }}
          />
        </section>
        <section>
          <h1 className="heading--h1">
            {cookiePolicy.childMarkdownRemark.frontmatter.title}
          </h1>
          <UpdatedDate>
            Last updated: {cookiePolicy.childMarkdownRemark.frontmatter.date}
          </UpdatedDate>
          <div
            dangerouslySetInnerHTML={{
              __html: cookiePolicy.childMarkdownRemark.html,
            }}
          />
        </section>
      </Container>
    </PageBody>
  </Layout>
)

const Container = styled.div`
  ${container}
  max-width: 800px;
  > * {
    margin-bottom: 40px;
  }
`

export default PrivacyPolicyPage

const UpdatedDate = styled.div`
  font-size: 0.9em;
  font-style: italic;
`

export const query = graphql`
  query {
    privacyPolicy: file(relativePath: { eq: "content/privacy-policy.md" }) {
      childMarkdownRemark {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
        }
      }
    }
    cookiePolicy: file(relativePath: { eq: "content/cookie-policy.md" }) {
      childMarkdownRemark {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
        }
      }
    }
  }
`
